import React, { Fragment } from 'react'
import IMG_ICON_BASES_CONCURSO from '../../../assets/img/icon02-bases-concurso.svg'
import IMG_ICON_BUENAS_PRACTICAS from '../../../assets/img/icon02-buenas-practicas.svg'
import IMG_ICON_PREGUNTAS_FRECUENTES from '../../../assets/img/icon02-preguntas-frecuentes.svg'
import IMG_ICON_CONTACTO from '../../../assets/img/icon02-contacto.svg'
import Home from './Home'
import { useNavigate } from 'react-router-dom'

export default function Main() {

    const navigateTo = useNavigate()

    const btnBasesOnClick = () => {
        navigateTo('/competition-rules')
    }

    const btnBuenasPracticasOnClick = () => {
        navigateTo('/good-practices')
    }

    const btnFaqOnClick = () => {
        navigateTo('/faq')
    }

    const btnContactoOnClick = () => {
        navigateTo('/contact-us')
    }

    const currentYear = new Date().getFullYear() % 100

    return (
        <main className="theme-light">
            <div className="banner banner-secondary rounded-0">
                <div className="container py-4">
                    <div className="row">
                        <div className="col-lg-6 col-12 pt-lg-5 px-lg-0 px-4">
                            <h1 className="fw-light text-white mx-auto mb-0 pt-3"><span className="fw-medium">Concurso Compartiendo</span><br/><span className="fw-bolder">Buenas Prácticas en Seguridad y Salud en el Trabajo</span></h1>
                        </div>
                    </div>
                </div>
                <div className="txt-year">
                    20<br /><span>
                        {currentYear}
                    </span>
                </div>
            </div>
            <div className="container py-5">
                <div className="row pt-5">
                    <div className="col-md-7 pb-5">

                        <h1 className="h2 fw-bold mb-5">Buenas Prácticas</h1>
                        <p className="mb-5">El propósito de compartir es que la experiencia y los conocimientos generados a través de los años de concurso sean un aporte permanente y fuente de inspiración para las nuevas versiones. A continuación puedes descargar y explorar las mejores prácticas que hemos seleccionado año a año.</p>

                        <hr className='solid-jasmine-color' />

                        <div className='my-4'>
                            <button className='rounded-pill px-4 py-2 ms-3 react-link btn btn-primary good-practices-button-fixed-width'
                                onClick={
                                    () => {
                                        navigateTo('/good-practices/guidelines')
                                    }
                                }
                            >
                                <span className="h5">
                                    Manuales de Buenas Prácticas
                                </span>
                            </button>

                        </div>
                        <div className='my-4'>
                            <button className='rounded-pill px-4 py-2 ms-3 react-link btn btn-primary good-practices-button-fixed-width'
                                onClick={
                                    () => {
                                        navigateTo('/good-practices/search')
                                    }
                                }
                            >
                                <span className="h5">
                                    Buscador de Buenas Prácticas
                                </span>
                            </button>
                        </div>

                        <hr className="solid-jasmine-color" />

                        <div className='my-4'>
                            <button className='rounded-pill px-4 py-2 ms-3 react-link btn btn-primary highlight good-practices-button-fixed-width'
                                onClick={
                                    () => {
                                        navigateTo('/good-practices/video-gallery')
                                    }
                                }
                            >
                                <span className="h5">
                                    Videos Empresas Ganadoras
                                </span>
                            </button>

                        </div>
                        <div className='my-4'>
                            <button className='rounded-pill px-4 py-2 ms-3 react-link btn btn-primary highlight good-practices-button-fixed-width'
                                onClick={
                                    () => {
                                        navigateTo('/good-practices/photo-gallery/2024')
                                    }
                                }
                            >
                                <span className="h5">
                                    Galería Fotos Evento 2024
                                </span>
                            </button>
                        </div>
                        <div className='my-4'>
                            <button className='rounded-pill px-4 py-2 ms-3 react-link btn btn-primary good-practices-button-fixed-width'
                                onClick={
                                    () => {
                                        navigateTo('/good-practices/photo-gallery/2023')
                                    }
                                }
                            >
                                <span className="h5">
                                    Galería Fotos Evento 2023
                                </span>
                            </button>
                        </div>
                        <Home />

                    </div>
                    <div className="col-md-4 ms-md-auto me-lg-4 me-0">
                        <div className="row row-cols-2 g-4 text-center mt-2 nav-icons">
                            <div className="col">
                                <a onClick={
                                    btnBasesOnClick
                                } className="p-3 d-block react-link">
                                    <img src={
                                        IMG_ICON_BASES_CONCURSO
                                    } className="img-fluid" />

                                </a>
                                <span className="small fw-bold lh-1 text-wrap" style={
                                    { width: '6rem' }
                                }>Bases del Concurso</span>
                            </div>
                            <div className="col page-active">
                                <a onClick={
                                    btnBuenasPracticasOnClick
                                } className="p-3 d-block react-link">
                                    <img src={
                                        IMG_ICON_BUENAS_PRACTICAS
                                    } className="img-fluid" />

                                </a>
                                <span className="small fw-bold lh-1 text-wrap" style={
                                    { width: '6rem' }
                                }>Buenas Prácticas</span>
                            </div>
                            <div className="col">
                                <a onClick={
                                    btnFaqOnClick
                                } className="p-3 d-block react-link">
                                    <img src={
                                        IMG_ICON_PREGUNTAS_FRECUENTES
                                    } className="img-fluid" />

                                </a>
                                <span className="small fw-bold lh-1 text-wrap" style={
                                    { width: '6rem' }
                                }>Preguntas Frecuentes</span>
                            </div>
                            <div className="col">
                                <a onClick={
                                    btnContactoOnClick
                                } className="p-3 d-block react-link">
                                    <img src={
                                        IMG_ICON_CONTACTO
                                    } className="img-fluid" />

                                </a>
                                <span className="small fw-bold lh-1">Contacto</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
