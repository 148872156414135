import React, { useEffect } from 'react'
import PhotoGalleryItem from './PhotoGalleryItem'

export default function PhotoGalleryRender(props) {

    const { year } = props;
    const baseUrl = 'https://mbp-cdn.nyc3.digitaloceanspaces.com';
    const itemsPerPage = 15;
    const maxPaginationButtons = 3;
    const [firstGalleryItem, setFirstGalleryItem] = React.useState(0);
    const [lastGalleryItem, setLastGalleryItem] = React.useState(0);
    const [photoNamePrefix, setPhotoNamePrefix] = React.useState('ceremonia_premiacion_cbpsst_2023_');
    const [currentPage, setCurrentPage] = React.useState(1);
    const [maxPages, setMaxPages] = React.useState(0);

    useEffect(() => {
        let firstItem;
        let lastItem;
        let prefix;

        // Determine last item and prefix based on the year
        switch (year) {
            case 2023:
                firstItem = 0;
                lastItem = 98;
                prefix = 'ceremonia_premiacion_cbpsst_2023_';
                break;
            case 2024:
                firstItem = 0;
                lastItem = 97;
                prefix = 'ceremonia_premiacion_cbpsst_2024_';
                break;
            default:
                lastItem = 0; // Default case to avoid errors
                prefix = '';
                break;
        }

        // Set the state variables based on the selected year
        setFirstGalleryItem(firstItem);
        setLastGalleryItem(lastItem);
        setPhotoNamePrefix(prefix);
        setMaxPages(Math.ceil((lastItem - firstGalleryItem + 1) / itemsPerPage));

        // Reset currentPage to 1 when year changes
        setCurrentPage(1);

    }, [year]); // Ensure this effect runs whenever the year changes

    var PrependZeros = function (str, len, seperator) {
        if (typeof str === 'number' || Number(str)) {
            str = str.toString();
            return (len - str.length > 0) ? new Array(len + 1 - str.length).join('0') + str : str;
        }
        else {
            for (var i = 0, spl = str.split(seperator || ' '); i < spl.length; spl[i] = (Number(spl[i]) && spl[i].length < len) ? PrependZeros(spl[i], len) : spl[i], str = (i == spl.length - 1) ? spl.join(seperator || ' ') : str, i++);
            return str;
        }
    };

    return (
        <div className="album py-5 bg-light">
            <div className="container">

                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">

                    {/* Gallery of PhotoGalleryItem using Pagination */}

                    {
                        Array.from({ length: itemsPerPage }).map((_, index) => {
                            const item = index + (currentPage - 1) * itemsPerPage;

                            if (item < firstGalleryItem || item > lastGalleryItem) {
                                return null; // Skip rendering if item is out of range
                            }

                            let fixedItemName = PrependZeros(item, 3);

                            return (
                                <PhotoGalleryItem
                                    key={item}
                                    photo={`${baseUrl}/${year}/${photoNamePrefix}${fixedItemName}.jpg`}
                                />
                            );
                        })
                    }

                </div>

                {/* Pagination */}

                <div className="d-flex justify-content-center my-5">
                    <div className="btn-group" role="group" aria-label="Basic example">

                        {
                            currentPage > 1 &&
                            <>
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    onClick={() => setCurrentPage(1)}
                                >
                                    &lt;&lt;
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                >
                                    &lt;
                                </button>
                            </>
                        }
                        {
                            Array.from({ length: maxPages }).map((_, index) => {
                                const page = index + 1;
                                const isCurrentPage = page === currentPage;
                                const isNearCurrentPage = page >= currentPage - maxPaginationButtons && page <= currentPage + maxPaginationButtons;
                                if (isCurrentPage || isNearCurrentPage) {
                                    return (
                                        <button
                                            key={index}
                                            type="button"
                                            className={`btn ${isCurrentPage ? 'btn-primary' : 'btn-outline-primary'}`}
                                            onClick={() => setCurrentPage(page)}
                                        >
                                            {page}
                                        </button>
                                    )
                                }
                                return null;
                            })
                        }
                        {
                            currentPage < maxPages &&
                            <>
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                >
                                    &gt;
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    onClick={() => setCurrentPage(maxPages)}
                                >
                                    &gt;&gt;
                                </button>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
