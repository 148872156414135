import React from 'react'
import Navbar from '../../components/Public/Navbar'
import Footer from '../../components/Public/Footer'
import Main from './GoodPractices/PhotoGallery'

export default function GoodPracticesPhotoGallery(
    props
) {

    const { year } = props

    return (
        <>
            <Navbar />
            <Main year={year} />
            <Footer />
        </>
    )
}
