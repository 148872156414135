import React, { Fragment } from 'react'
import IMG_ICON_BASES_CONCURSO from '../../../assets/img/icon02-bases-concurso.svg'
import IMG_ICON_BUENAS_PRACTICAS from '../../../assets/img/icon02-buenas-practicas.svg'
import IMG_ICON_PREGUNTAS_FRECUENTES from '../../../assets/img/icon02-preguntas-frecuentes.svg'
import IMG_ICON_CONTACTO from '../../../assets/img/icon02-contacto.svg'
import IMG_RETURN_ARROW from '../../../assets/img/return-arrow.png'
import Search from './Search'
import { useNavigate } from 'react-router-dom'

export default function Main() {

    const navigateTo = useNavigate()

    const btnBasesOnClick = () => {
        navigateTo('/competition-rules')
    }

    const btnBuenasPracticasOnClick = () => {
        navigateTo('/good-practices')
    }

    const btnFaqOnClick = () => {
        navigateTo('/faq')
    }

    const btnContactoOnClick = () => {
        navigateTo('/contact-us')
    }

    const currentYear = new Date().getFullYear() % 100

    return (
        <main className="theme-light">
            <div className="banner banner-secondary rounded-0">
                <div className="container py-4">
                    <div className="row">
                        <div className="col-lg-6 col-12 pt-lg-5 px-lg-0 px-4">
                        <h1 className="fw-light text-white mx-auto mb-0 pt-3"><span className="fw-medium">Concurso Compartiendo</span><br/><span className="fw-bolder">Buenas Prácticas en Seguridad y Salud en el Trabajo</span></h1>
                        </div>
                    </div>
                </div>
                <div className="txt-year">
                    20<br /><span>
                        {currentYear}
                    </span>
                </div>
            </div>
            <div className="container py-5">
                <div className="row pt-5">
                    <div className="col-md-12">
                        <Search
                            btnBasesOnClick={btnBasesOnClick}
                            btnBuenasPracticasOnClick={btnBuenasPracticasOnClick}
                            btnFaqOnClick={btnFaqOnClick}
                            btnContactoOnClick={btnContactoOnClick}
                            IMG_ICON_BASES_CONCURSO={IMG_ICON_BASES_CONCURSO}
                            IMG_ICON_BUENAS_PRACTICAS={IMG_ICON_BUENAS_PRACTICAS}
                            IMG_ICON_PREGUNTAS_FRECUENTES={IMG_ICON_PREGUNTAS_FRECUENTES}
                            IMG_ICON_CONTACTO={IMG_ICON_CONTACTO}
                            IMG_RETURN_ARROW={IMG_RETURN_ARROW}
                        />
                    </div>

                </div>
            </div>
        </main>
    )
}
