import React, { useEffect, useState, useRef } from 'react'
import IMG_ICON_BASES_CONCURSO from '../../../assets/img/icon-bases-concurso.svg'
import IMG_ICON_BUENAS_PRACTICAS from '../../../assets/img/icon-buenas-practicas.svg'
import IMG_ICON_PREGUNTAS_FRECUENTES from '../../../assets/img/icon-preguntas-frecuentes.svg'
import IMG_ICON_CONTACTO from '../../../assets/img/icon-contacto.svg'
import IMG_ConcursoBuenasPracticas from '../../../assets/img/concurso_buenas_practicas.png'
import IMG_POPUP from '../../../assets/img/popup-premiacion.jpg'
import { useNavigate } from 'react-router-dom'
import contestService from '../../../services/contests'
import CountUp from 'react-countup'
import ModalComponent from '../../../components/Private/ModalComponent'

export default function Main() {

    const navigateTo = useNavigate()
    const [activeContest, setActiveContest] = useState(null)
    const [daysForClose, setDaysForClose] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [popupLink, setPopupLink] = useState('https://docs.google.com/forms/d/1gkvR2UEwXu8vIn-vhCGr7L-v-9eyqKJtUEDF6Q6omQE/viewform?edit_requested=true')
    const contentRef = useRef(null)

    const [currentDisplay, setCurrentDisplay] = useState('winners')

    const [winnerList, setWinnerList] = useState(
        [
            {
                title1: 'Liderazgo Ejecutivo',
                title2: 'Regla 1: Asumir el liderazgo. Demostrar el compromiso.',
                practices: [
                    {
                        business: 'SalfaCorp S.A.',
                        goodPractice: 'Comité ejecutivo de seguridad, CES. Programa de liderazgo, participación y compromiso gerencial.',
                    },
                ]
            },
            {
                title1: 'Identificación de peligros y evaluación de riesgos',
                title2: 'Regla 2: Identificar peligros. Evaluar los riesgos.',
                practices: [
                    {
                        business: 'Empresa de Mantenciones y Servicios Salfa S.A.',
                        goodPractice: 'Lecciones que salvan vidas.',
                    },
                ]
            },
            {
                title1: 'Programas de Seguridad y Salud en el Trabajo',
                title2: 'Regla 3: Definir metas. Elaborar programas.',
                practices: [
                    {
                        business: 'Flesan Minería S.A.',
                        goodPractice: 'Plan estratégico, construyendo una Cultura de Seguridad 2024.',
                    },
                    {
                        business: 'Transportes Santolaya Ltda.',
                        goodPractice: 'Asegura tu Ruta.',
                    }
                ]
            },
            {
                title1: 'Sistemas de Gestión de Seguridad y Salud en el Trabajo',
                title2: 'Regla 4: Garantizar un sistema seguro y saludable. Estar bien organizados.',
                practices: [
                    {
                        business: 'Puga, Mujica Asociados S.A.',
                        goodPractice: 'Modo operativo de Trabajo MOT.',
                    },
                ]
            },
            {
                title1: 'Medidas de control de riesgo',
                title2: 'Regla 5: Velar por la seguridad y la salud en las máquinas, equipos y lugares de trabajo.',
                practices: [
                    {
                        business: 'Constructora ALM Imagina.',
                        goodPractice: 'Mejoras de seguridad en Shaft.',
                    },
                ]
            },
            {
                title1: 'Formación y competencias',
                title2: 'Regla 6: Mejorar las cualificaciones. Desarrollar las competencias.',
                practices: [
                    {
                        business: 'SalfaCorp S.A.',
                        goodPractice: 'Aplicación SALFAPRENDE.',
                    },
                ]
            },
            {
                title1: 'Participación de los trabajadores en la Seguridad y Salud en el Trabajo',
                title2: 'Regla 7: Invertir en las personas. Motivar a través de la participación.',
                practices: [
                    {
                        business: 'Isiete Constructora.',
                        goodPractice: 'Sentémonos a conversar de riesgos críticos.',
                    },
                ]
            },
        ]
    )

    const [featuredList, setFeaturedList] = useState(
        [
            {
                title1: 'Liderazgo Ejecutivo',
                title2: 'Regla 1: Asumir el liderazgo. Demostrar el compromiso.',
                practices: [
                    {
                        business: 'Echeverría Izquierdo S.A.',
                        goodPractice: 'Comité de Alta Gerencia de Seguridad.',
                    },
                    {
                        business: 'Constructora Socovesa.',
                        goodPractice: 'Comunicación Transversal con apoyo tecnológico.',
                    }
                ]
            },
            {
                title1: 'Identificación de peligros y evaluación de riesgos',
                title2: 'Regla 2: Identificar peligros. Evaluar los riesgos.',
                practices: [
                    {
                        business: 'Besalco S.A.',
                        goodPractice: 'Control de Fatiga y somnolencia.',
                    },
                    {
                        business: 'Flesan S.A.',
                        goodPractice: 'Banderas de alerta de identificación y manejo de riesgos.',
                    }
                ]
            },
            {
                title1: 'Programas de Seguridad y Salud en el Trabajo',
                title2: 'Regla 3: Definir metas. Elaborar programas.',
                practices: [
                    {
                        business: 'Constructora Inmobiliaria e Inversiones Oval Ltda.',
                        goodPractice: 'Programa elige bienestar en Obra.',
                    },
                    {
                        business: 'RVC Ingeniería y Construcción',
                        goodPractice: 'Programa elige bienestar.',
                    }
                ]
            },
            {
                title1: 'Sistemas de Gestión de Seguridad y Salud en el Trabajo',
                title2: 'Regla 4: Garantizar un sistema seguro y saludable. Estar bien organizados.',
                practices: [
                    {
                        business: 'Constructora Almagro S.A.',
                        goodPractice: 'Evaluación de actividades de prevención de riesgos.',
                    },
                    {
                        business: 'Empresa de Mantenciones y Servicios Salfa S.A.',
                        goodPractice: 'Salfabot.',
                    }
                ]
            },
            {
                title1: 'Medidas de control de riesgo',
                title2: 'Regla 5: Velar por la seguridad y la salud en las máquinas, equipos y lugares de trabajo.',
                practices: [
                    {
                        business: 'SURALIS',
                        goodPractice: 'Seguridad en espacios confinados en SURALIS. Buenas Prácticas ',
                    },
                    {
                        business: 'Geovita S.A.',
                        goodPractice: 'Implementación de Camaras en 360°, en máquinas y equipos.',
                    }
                ]
            },
            {
                title1: 'Formación y competencias',
                title2: 'Regla 6: Mejorar las cualificaciones. Desarrollar las competencias.',
                practices: [
                    {
                        business: 'Empresa de Mantenciones y Servicios Salfa S.A.',
                        goodPractice: 'Recorrido virtual primera persona.',
                    },
                    {
                        business: 'Constructora Inmobiliaria e Inversiones Oval Ltda.',
                        goodPractice: 'Programa de entrenamiento estratégico SSO.',
                    }
                ]
            },
            {
                title1: 'Participación de los trabajadores en la Seguridad y Salud en el Trabajo',
                title2: 'Regla 7: Invertir en las personas. Motivar a través de la participación.',
                practices: [
                    {
                        business: 'Flesan S.A.',
                        goodPractice: 'Percepción del Riesgo.',
                    },
                    {
                        business: 'Flesan Minería S.A.',
                        goodPractice: 'Juego de Cartas “Te cuido”.',
                    }
                ]
            },
        ]
    )

    const btnBasesOnClick = () => {
        navigateTo('/competition-rules')
    }

    const btnBuenasPracticasOnClick = () => {
        navigateTo('/good-practices')
    }

    const btnFaqOnClick = () => {
        navigateTo('/faq')
    }

    const btnContactoOnClick = () => {
        navigateTo('/contact-us')
    }

    const btnParticiparOnClick = () => {
        //open website
        window.open('https://empresa.buenaspracticasensst.cl/sign-up', '_blank')
    }

    const btnToggleWinnerOrFeatured = () => {
        if (currentDisplay === 'winners') {
            setCurrentDisplay('featured')
        } else {
            setCurrentDisplay('winners')
        }
        contentRef.current.scrollIntoView({ behavior: 'smooth' })
        //window.scrollTo(0, 0);
    }

    const handleContestLoading = async () => {
        try {
            const result = await contestService.showActive()
            setActiveContest(result)
            let deadLine = result.contestClosingDateTime;
            deadLine = new Date(deadLine);
            deadLine = new Date(deadLine.getTime() + deadLine.getTimezoneOffset() * 60000)
            let today = new Date();
            let diff = deadLine.getTime() - today.getTime();
            let days = Math.ceil(diff / (1000 * 60 * 60 * 24));
            setDaysForClose(days)
        } catch (error) {
            console.log(error)
        }
    }

    const handleCompetitionHistory = () => {
        return <div className="col-md-7 pb-5">
            <h1 className="mb-4 h3 fw-bold">Historia del Concurso</h1>
            <p>La Mesa Directiva Nacional de la Cámara Chilena de la Construcción A.G., en conjunto con Mutual de Seguridad CChC, conscientes del hecho de que los trabajadores de la industria de la construcción deben enfrentar riesgos que son potencialmente graves y/o fatales en la realización de sus funciones, desde el año 2008 organizan el Concurso “COMPARTIENDO BUENAS PRÁCTICAS EN SEGURIDAD Y SALUD EN EL TRABAJO”, siendo, la del año 2024, su décimo séptima convocatoria.</p>
            <p>El objetivo de este concurso es buscar soluciones a la necesidad de implementar nuevas y mejores formas de gestionar el riesgo, que nos permitan contar día a día con un lugar de trabajo más seguro y así lograr alcanzar el desafío Cero Accidentes Fatales.</p>
            <p>A través de la premiación de Buenas Prácticas de Seguridad y Salud en el Trabajo de Empresas ligadas al rubro de la construcción. que cumpla con ser Socia de la Cámara Chilena de la Construcción A.G. y/o Adherente de Mutual de Seguridad CChC, se busca destacar la creación, innovación e implementación de buenas prácticas que generen efectos positivos en las áreas de seguridad y/o salud en el trabajo.</p>
            <p>Las categorías de postulación están basadas en las 7 Reglas de Oro que la Cámara Chilena de la Construcción promueve entre sus Socios como herramienta para construir Cultura de Seguridad al interior de las organizaciones y para efectos del Concurso corresponden a las siguientes categorías:</p>

            <p>Liderazgo Ejecutivo.<br />
                Regla 1: Asumir el liderazgo. Demostrar el compromiso.</p>

            <p>Identificación de peligros y evaluación de riesgos<br />
                Regla 2: Identificar peligros. Evaluar los riesgos.</p>

            <p>Programas de Seguridad y Salud en el Trabajo<br />
                Regla 3: Definir metas. Elaborar programas.</p>

            <p>Sistemas de Gestión de Seguridad y Salud en el Trabajo<br />
                Regla 4: Garantizar un sistema seguro y saludable. Estar bien organizados.</p >

            <p>Medidas de control de riesgo<br />
                Regla 5: Velar por la seguridad y la salud en las máquinas, equipos y lugares de trabajo.</p >

            <p>Formación y competencias<br />
                Regla 6: Mejorar las cualificaciones. Desarrollar las competencias.</p >

            <p>Participación de los trabajadores en la Seguridad y Salud en el Trabajo<br />
                Regla 7: Invertir en las personas. Motivar a través de la participación.</p >
        </div>
    }

    const handleWinnerDisplay = () => {
        return <>
            <h1 className="pt-4 mb-4 h3 fw-bold">Empresas Ganadoras</h1>
            <p>El siguiente listado está ordenado en base a las 7 Reglas de Oro y corresponde a las siguientes categorías:</p>
            {
                winnerList.map((item, index) => {
                    return <div key={index} className="mb-5">
                        <h2 className="mb-2 h6 fw-bold verde-mutual-intenso-text"><u>{item.title1}</u></h2>
                        <h3 className="mb-4 h5 fw-bold verde-mutual-intenso-text">{item.title2}</h3>
                        <div>
                            {
                                item.practices.map((practice, index) => {
                                    return <div key={index} className='pb-4'>
                                        <p className="mb-4 h5">Empresa: {practice.business}</p>
                                        <p className="h6">Buena Práctica: {practice.goodPractice}</p>
                                    </div>
                                })
                            }
                            <hr />
                        </div>
                    </div>
                })
            }
            <a onClick={btnToggleWinnerOrFeatured} className='fw-bold special-link h4'>
                &lt;&lt; Empresas destacadas
            </a>
        </>
    }

    const handleFeaturedDisplay = () => {
        return <>
            <h1 className="pt-4 mb-4 h3 fw-bold">Empresas Destacadas</h1>
            <p>El siguiente listado está ordenado en base a las 7 Reglas de Oro y corresponde a las siguientes categorías:</p>
            {
                featuredList.map((item, index) => {
                    return <div key={index} className="mb-5">
                        <h2 className="mb-2 h6 fw-bold verde-mutual-intenso-text"><u>{item.title1}</u></h2>
                        <h3 className="mb-4 h5 fw-bold verde-mutual-intenso-text">{item.title2}</h3>
                        <div>
                            {
                                item.practices.map((practice, index) => {
                                    return <div key={index} className='pb-4'>
                                        <p className="mb-4 h5">Empresa: {practice.business}</p>
                                        <p className="h6">Buena Práctica: {practice.goodPractice}</p>
                                    </div>
                                })
                            }
                            <hr />
                        </div>
                    </div>
                })
            }
            <a onClick={btnToggleWinnerOrFeatured} className='fw-bold special-link h4'>
                &lt;&lt; Empresas ganadoras
            </a>
        </>
    }

    const handleDisplatWinnerOrFeatured = () => {
        return <div className="col-md-7 pb-5">
            <h1 className="mb-4 h4">
                Gracias por compartir buenas prácticas en seguridad y  salud en el trabajo que contribuyen a la cultura de seguridad y la prevención de accidentes.
            </h1>
            <h1 className="mb-4 h4">
                Agradecemos a todas las empresas participantes y felicitamos a las empresas ganadoras y destacadas en esta versión del concurso.
            </h1>
            {
                currentDisplay === 'winners' ?
                    handleWinnerDisplay()
                    : handleFeaturedDisplay()
            }
        </div>
    }

    const handleWebContent = () => {
        //return handleCompetitionHistory()
        return handleDisplatWinnerOrFeatured()
    }

    const handleWebSections = () => {
        return <div className="col-md-4 ms-md-auto me-lg-4 me-0">
            <div className="row row-cols-2 g-4 text-center mt-2 nav-icons">
                <div className="col">
                    <a onClick={
                        btnBasesOnClick
                    } className="p-3 d-block react-link">
                        <img src={
                            IMG_ICON_BASES_CONCURSO
                        } className="img-fluid" />

                    </a>
                    <span className="small fw-bold lh-1 text-wrap" style={
                        { width: '6rem' }
                    }>Bases del Concurso</span>
                </div>
                <div className="col">
                    <a onClick={
                        btnBuenasPracticasOnClick
                    } className="p-3 d-block react-link">
                        <img src={
                            IMG_ICON_BUENAS_PRACTICAS
                        } className="img-fluid" />

                    </a>
                    <span className="small fw-bold lh-1 text-wrap" style={
                        { width: '6rem' }
                    }>Buenas Prácticas</span>
                </div>
                <div className="col">
                    <a onClick={
                        btnFaqOnClick
                    } className="p-3 d-block react-link">
                        <img src={
                            IMG_ICON_PREGUNTAS_FRECUENTES
                        } className="img-fluid" />

                    </a>
                    <span className="small fw-bold lh-1 text-wrap" style={
                        { width: '6rem' }
                    }>Preguntas Frecuentes</span>
                </div>
                <div className="col">
                    <a onClick={
                        btnContactoOnClick
                    } className="p-3 d-block react-link">
                        <img src={
                            IMG_ICON_CONTACTO
                        } className="img-fluid" />

                    </a>
                    <span className="small fw-bold lh-1">Contacto</span>
                </div>
            </div>
        </div>
    }

    useEffect(() => {
        setModalTitle('')
        setShowModal(false)
        handleContestLoading()
    }, [])

    const currentYear = new Date().getFullYear() % 100

    return (
        <main className="theme-blue">
            <div className="banner banner-home">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-7 col-12 pt-lg-5 px-lg-0 px-5">
                            <h1 className="fw-light text-white mx-auto">Bienvenido al Concurso<br /><span className="fw-medium">Compartiendo Buenas Prácticas en Seguridad y Salud en el Trabajo</span></h1>
                            <div className="row row-cols-12 row-cols-lg-2 g-4 mt-lg-4 mt-3 d-flex align-items-center">

                                <div className="col">
                                    {/*
                                        <a
                                            className="btn btn-outline-primary btn-lg rounded-pill btn-participate px-lg-5 px-4 react-link"
                                            onClick={btnParticiparOnClick}
                                        >
                                            Participar
                                        </a>
                                    */}
                                </div>
                                <div className="col">
                                    {/*
                                        <div className="d-flex align-items-center">
                                            <span className="counter px-4 fw-bold">
                                                <CountUp
                                                    end={daysForClose}
                                                    duration={2}
                                                    start={daysForClose + 10}
                                                />
                                            </span>
                                            <span className="ms-3 fw-bold txt-days-to-close">DÍAS PARA<br />EL CIERRE</span>
                                        </div>
                                    */}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="txt-year">
                    20<br /><span>
                        {currentYear}
                    </span>
                </div>
            </div>
            <div className="container py-5" ref={contentRef}>
                <ModalComponent
                    id='alertModal'
                    title={modalTitle}
                    show={showModal}
                    setShow={setShowModal}
                    modalSize='modal-lg'
                >
                    {/*}
                    <div className='row'>
                        <div className='my-4 text-center'>
                            <img src={IMG_ConcursoBuenasPracticas} className="img-fluid " />
                        </div>
                    </div>
                    */}

                    {/*
                    <p className='text-center text-dark mt-4'>
                        Proceso 2024 cerrado. Agradecemos tu participación.
                    </p>
                    <p className='text-center'>
                        <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSe7ScXKg7jDNGiWAC3OPIW-F1xPBCzkxRLhac_Vm0GcBjuB1A/viewform"
                            className='btn btn-primary d-block d-sm-inline-block rounded-pill px-4 py-2 btn-lg'
                        >Inscribirme</a>
                    </p>
                    */}

                    <a href={popupLink} target="_blank">
                        <img src={IMG_POPUP} className="img-fluid" />
                    </a>
                </ModalComponent>
                <div className="row pt-5">
                    {handleWebContent()}
                    {handleWebSections()}
                </div>
            </div>
        </main>
    )
}
